<template>
<div class="foniy-building-switch">
    <div class="foniy-building-switch-container " :class="{'active':toggle, 'show':disabled, 'loading':loading}" @click="Open()">
        <div class="foniy-building-switch-round" v-if="!disabled">
            <svg class="active-svg" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.13574 4.27142L4.02724 7.17657L10.1649 1.03894" stroke="#004787" stroke-width="2.04588" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <svg width="18" class="unactive-svg" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2L16 16" stroke="#004787" stroke-width="3.29412" stroke-linecap="round" />
                <path d="M16 2L2 16" stroke="#004787" stroke-width="3.29412" stroke-linecap="round" />
            </svg>
            <i class="loading-svg el-icon-loading">

            </i>

        </div>
    </div>
    <div class="modal" :class="{'active' : modal}">
        <div class="modal-cover"></div>
        <div class="modal-container">
            <div class="confirm-image">
                <img src="@/assets/img/building-confirm.svg" alt="">
            </div>
            <div class="confirm-text">
                <h1 class="">{{ $t('confirm_change_status') }}</h1>
                <p>{{ $t('confirm_change_status_info') }}</p>
            </div>

            <div class="modal-button">
                <button class="cancel mr-10px" @click="modal=false" :disabled="loading" :class="{'opacity-6':loading}">
                    <span>{{ $t('cancel') }}</span>
                </button>
                <button class="save" @click="_Edit()" :disabled="loading" :class="{'opacity-6':loading}">
                    <span v-if="!loading"> <i class="el-icon-check mr-2"></i> Xa</span>
                    <span v-else> <i class="el-icon-loading"></i></span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['value', 'id', 'disabled'],
    data() {
        return {
            toggle: false,
            modal: false,
            loading: false,
            value1: false

        }
    },
    watch: {
        value: {
            handler(after) {
                if(after !=null){
                    this.toggle = after;
                }
                else{
                    this.toggle = false;
                }
               
            },
            immediate: true,
        },
        toggle(val) {
            this.$emit('input', val);
        },
    },
    methods: {
        _Edit() {
            this.toggle=!this.toggle
            let data = [{
                "building": this.id,
                "is_invest": this.toggle
            }]
            this.loading = true
            axios.post(`/building/building_to_invest/`, data)
                .then(() => {
                    this.$notify({
                        title: this.$t('data_saved'), 
                        type: 'success'
                    });
                    this.modal=false
                })
                .catch(() => { 
                    this.modal=false
                    this.toggle=!this.toggle
                    this.$notify({
                        title: this.$t('error_with_server'), 
                        type: 'error'
                    });
                })
                .finally(() => {
                    this.loading = false
                })

        },
        Open(){
            if(!this.disabled){
                this.modal=true
            }
        }
    }
}
</script>

<style lang="scss">
.foniy-building-switch {
    .foniy-building-switch-container {
        width: 50px;
        background: #BEBEBE;
        height: 26px;
        border-radius: 15px;
        cursor: pointer;
        transition: all 0.2s ease-in;
        margin: auto;

        .foniy-building-switch-round {
            width: 26px;
            height: 26px;
            background: #F2F4FF;
            border-radius: 50%;
            border: 1.16505px solid #BEBEBE;
            box-shadow: 1.74757px 2.3301px 5.82524px 1.74757px rgba(115, 115, 115, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in;
            transform: translateX(0%);

            svg {
                width: 12px;
            }

        }

        .active-svg {
            display: none;
        }

        .loading-svg {
            display: none;
        }

        &.active {
            background: #00DE8E;

            .foniy-building-switch-round {
                border: 1.16505px solid #00DE8E;
                box-shadow: -1.74757px 1.74757px 5.82524px 1.74757px rgba(115, 115, 115, 0.25);
                transform: translateX(100%);

            }

            .active-svg {
                display: block;
            }

            .unactive-svg {
                display: none;
            }
        }

        &.loading {
            opacity: 0.6;

            .active-svg {
                display: none;
            }

            .unactive-svg {
                display: none;
            }

            .loading-svg {
                display: block;
            }
        }

        &.show {
            height: 20px;
            width: 20px;
        }
    }

}

.confirm-image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;

}

.confirm-text {
    h1 {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        font-feature-settings: 'pnum'on, 'lnum'on;
        color: #FF0000 !important;
        text-align: center;
        margin-bottom: 10px !important;
    }

    p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-feature-settings: 'pnum'on, 'lnum'on;
        color: #004787;
        text-align: center;
        margin-bottom: 20px !important;
    }
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2003;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: all 0.3s ease-in;
    display: none;
    backdrop-filter: blur(5px);

    &.active {
        display: flex;

        .modal-container-content {
            top: 0;
        }
    }

    .modal-cover {
        position: absolute;
        top: 0;
        left: 0;
        background: black;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        z-index: 0;
    }

    .modal-container-content {
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-in;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        z-index: 1;
    }

    .modal-container {
        position: relative;
        z-index: 1;
        max-width: 550px;
        width: 100%;
        background: #fff;
        padding: 30px 30px;
        background: #FFFFFF;
        box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.25);
        border-radius: 25px;

        .modal-container-img {
            display: flex;
            justify-content: center;

            img {
                max-width: 100px;
            }
        }

        h1 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            font-feature-settings: 'pnum'on, 'lnum'on;
            color: #004787;
            margin: 0;
        }

        h2 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            font-feature-settings: 'pnum'on, 'lnum'on;
            color: #004787;
            margin: 0;
            text-align: center;
            margin-top: 20px;
        }

        .success-texts-container {
            display: flex;
            justify-content: center;
        }

        .success-texts {
            padding: 20px 0px;

            .success-texts-item {
                width: 100%;
                padding: 5px;
                display: flex;

                p {
                    margin: 0;
                    font-family: 'Raleway';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #004787;
                    line-height: 18px;

                }

                .success-texts-item-title {
                    min-width: 150px;
                    font-weight: 700;
                }
            }

        }

        .modal-input-items {
            padding: 30px 0px;
            width: 100%;
        }

        .modal-input-item {
            padding-top: 2px;
            width: 100%;

            p {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                color: #929292;
                margin: 0;
                margin-bottom: 5px;
                padding-left: 10px;
            }

            .el-input__inner {
                background: #FFFFFF;
                box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
                border-radius: 16px;
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                font-feature-settings: 'pnum'on, 'lnum'on;
                color: #004787;
                height: auto;
                padding: 16px;
                width: 100%;
            }

            &.disabled {
                .el-input__inner {
                    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25) !important;
                    background: #EAEAEA;
                }

            }

            .choose-role {
                display: flex;
                align-items: center;
                width: 100%;
                padding-top: 10px;

                .choose-role-item {
                    width: 50%;

                    button {
                        width: 100%;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
                        border-radius: 16px;
                        padding: 5px 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 46px;

                        img {
                            margin-right: 5px;
                            height: 20px;
                        }

                        p {
                            margin: 0;
                            font-family: 'Raleway';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 18px;
                            font-feature-settings: 'pnum'on, 'lnum'on;
                            color: #004787;
                        }

                    }

                    &.active {
                        button {
                            background: #004787;

                            p {
                                color: #fff;
                            }

                            img {
                                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(101%) contrast(101%);
                            }
                        }

                    }
                }

                .choose-role-item:nth-child(1) {
                    padding-right: 5px;
                }

                .choose-role-item:nth-child(2) {
                    padding-left: 5px;
                }

            }

        }

        .modal-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .save {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
                color: #FFFFFF;
                background: #004787;
                border-radius: 16px;
                padding: 14px 20px;
                min-width: 140px;
            }

            .cancel {
                font-family: 'Raleway';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                color: #004787;
                background: #DEF0FF;
                border-radius: 16px;
                padding: 14px 20px;
                min-width: 130px;
                margin-right: 10px;
            }

            i {
                margin-right: 5px;
                font-family: 'Raleway-Bold';
                font-weight: 600;
            }
        }
    }

    .el-form-item__error {
        padding-left: 10px;
        font-family: 'Raleway';
        font-style: normal;
    }

}
</style>
